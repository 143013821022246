@tailwind base;
@tailwind components;
@tailwind utilities;

@import "style";

.nav-btn {
  @apply bg-gray-100 inline-block py-2 px-4;
}

.nav-btn-active {
  @apply border-l-2 border-t-2 border-r-2 border-gray-500 rounded-t;
}

.btn {
  @apply bg-yellow-400 hover:bg-yellow-500 text-center p-1 rounded leading-5 shadow-md border-2 border-amber-500;
}

.-mb-px {
  margin-bottom: -2px;
}
