html,
body {
  height: 100%;
  margin: 0;
}

html {
  overflow-y: scroll;
}

xmp {
  font-size: 12px;
}

.no-wrap {
  white-space: nowrap;
}

dl {
  font-weight: bold;
  margin:10px 0;
  dd {
    border-left: 2px solid silver;
    padding-left: 15px;
    font-weight: normal;
  }
}

.active {
  background-color: aquamarine;
}
